<template>
	<article class="column is-12-mobile is-half-desktop is-one-third-widescreen">
		<h3 class="is-size-5 is-uppercase has-text-grey-dark pl-5">Meus <strong>Dealers</strong></h3>
		<ul v-if="dealers.length > 0" class="dashboard__panel dashboard__list">
			<li class="dashboard__list__item dashboard__list__dealers" v-for="d in dealers.slice(0, 6)" :key="d.id">
				<a class="" @click="redirectRating(d.id)">
					<span class="dashboard__list__name">
						<span class="is-ellipsis is-capitalized">{{ d.name }}</span>
					</span>
					<span class="dashboard__list__rate large is-medium">
						<b-tooltip :label="label(d.status)" position="is-top">
							<b-icon :icon="icon(d.status)"></b-icon>
						</b-tooltip>
					</span>
				</a>
			</li>
		</ul>
		<Results v-else class="dashboard__panel dashboard__list dashboard__results" title="" message="<br><strong>Nenhum</strong> dealer atribuído." />
	</article>
</template>

<script>
import Results from '@/components/Results'
import '@/mixins/generic'

export default {
	name: 'DealersDashboard',
	components: {
		Results
	},
	props: {
		dealers: {
			type: Array,
			required: true
		},
		ratingId: {
			type: [String, Number]
		}
	},
	data() {
			return {
			labels: {
				require_payment: 'Aguardando Faturamento',
				waiting: 'Em Avaliação',
				're-evaluated': 'Reavaliado',
				rated: 'Aguardando Conclusão',
				contested: 'Em Contestação',
				// waiting_payment: 'Aguardando Pagamento',
				accepted: 'Concluído'
			},
			icons: {
				require_payment: 'cash-plus',
				waiting: 'clipboard-text-play-outline',
				're-evaluated': 'clipboard-text-multiple-outline',
				rated: 'clipboard-check-outline',
				contested: 'clipboard-text-off-outline',
				// waiting_payment: 'account-cash-outline',
				accepted: 'check'
			}
		}
	},
	methods: {
		label(labelName) {
			return this.labels[labelName]
		},
		icon(iconName) {
			return this.icons[iconName]
		},
		redirectRating(dealerId) {
			this.$router.push(`/ratings/rate/${dealerId}-${this.ratingId}`)
		}
	}
}
</script>
