<template>
	<article class="column is-12-mobile is-half-desktop is-one-third-widescreen">
		<h3 class="is-size-5 is-uppercase has-text-grey-dark pl-5">{{ order }} <strong>10</strong></h3>
		<ul v-if="items.length > 0" class="dashboard__panel dashboard__list">
			<li class="dashboard__list__item" v-for="(d, i) in items.slice(0, 10)" :key="d.id">
				<span class="dashboard__list__position">{{ position(i) }}º</span>
				<span class="dashboard__list__name">
					<span class="is-ellipsis is-capitalized">{{ d.name }}</span>
				</span>
				<span class="dashboard__list__rate is-medium">
					<b-tooltip :label="colorLabel(d.rate)" position="is-top">
						<svg-icon icon="star" :class="color(d.rate)"></svg-icon>
						<span class="rate">{{ d.rate }}</span>
					</b-tooltip>
				</span>
			</li>
		</ul>
		<Results v-else class="dashboard__panel dashboard__list dashboard__results" title="" message="Ainda não existe<br><strong>nenhuma</strong> avaliação concluída." />
	</article>
</template>

<script>
import Icon from '@/components/Icon'
import Results from '@/components/Results'
import '@/mixins/generic'

export default {
	name: 'Ranking',
	components: {
		Results,
		'svg-icon': Icon
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		order: {
			type: String,
			default: 'top'
		}
	},
	methods: {
		position(i) {
			return this.order == 'bottom' ? this.items.length - i : i + 1
		}
	}
}
</script>
